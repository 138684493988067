import React from 'react';
import CartIcon from '../ui/icons/CartIcon';
import { styled } from 'linaria/react';
import AddToCart from '../Cart/AddToCart';
import ProductLink from '@jetshop/ui/ProductLink';

const Wrapper = styled('div')`
  z-index: 1;
  scale: 1.5;

  &:hover {
    cursor: pointer;
  }
`;

const QuickBuyButton = styled('button')``;

const QuickBuyIcon = () => {
  return <CartIcon />;
};

export const QuickBuy = ({ product, categoryPath }) => {
  const hasVariants = product?.hasVariants;
  const variants = hasVariants && product?.variants?.values?.length;
  const hasOneVariant = variants === 1;
  const firstVariant = hasOneVariant ? product?.variants?.values?.[0] : product;
  const colors = product?.customFields?.find?.(
    field => field?.key === 'other-colors'
  )?.listValues?.length;

  const shouldNavigateToProduct = colors > 1 || variants > 1;

  return (
    <Wrapper>
      {shouldNavigateToProduct ? (
        <ProductLink product={product} categoryPath={categoryPath}>
          <QuickBuyIcon />
        </ProductLink>
      ) : (
        <AddToCart
          product={product}
          quantity={1}
          selectedVariation={firstVariant}
        >
          {add => (
            <QuickBuyButton
              onClick={e => {
                e.preventDefault();
                add();
              }}
            >
              <QuickBuyIcon />
            </QuickBuyButton>
          )}
        </AddToCart>
      )}
    </Wrapper>
  );
};
